import { GatsbyBrowser } from "gatsby"
import React from "react"

import { Layout } from "./src/components/Layout"

/* Wraps the entire page in a Layout component - same as Gatsby SSR API */
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <Layout>{element}</Layout>
}

/**
 * On each route update (incl. app load), change <a> tags with anchors for smooth scrolling behavior
 */
export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    const anchorSelector = anchor.getAttribute("href")

    if (!anchorSelector || anchorSelector === "#") return null

    anchor.addEventListener("click", (e) => {
      e.preventDefault()

      const querySelectorEl = document.querySelector(anchorSelector)
      if (!querySelectorEl) return null

      const scrollTarget = querySelectorEl.getBoundingClientRect().top ?? 0
      const scrollTargetAdjusted = scrollTarget + window.scrollY - 20

      window.scrollTo({
        behavior: "smooth",
        top: scrollTargetAdjusted,
      })
    })
  })
}
