interface IStyleVars {
  colors: Record<string, string>
  easings: Record<string, string>
  fonts: Record<string, string>
  timings: Record<string, string>
}

export const styleVars: IStyleVars = {
  colors: {
    buttonBg: '#323234',
    buttonBgHover: '#424345',
    buttonDescription: '#C4C4C4',
    buttonText: '#fffdee',
    codeComment: '#66bb6a',
    codeLineNumber: 'rgba(255, 255, 255, 0.4)',
    codeReserved: '#ba68c8',
    codeVariable: '#81d4fa',
    externalArrowBorder: '#AEACA4',
    footerArrow: '#DCDACE',
    heroTitleDecorationBorder: '#FFFBD8',
    iconBg: '#4A4743',
    iconBgHover: '#7B7975',
    memberBlue: '#4C54FF',
    memberGreen: '#21A93F',
    memberOrange: '#F7501C',
    memberPurple: '#7E22F3',
    memberTeal: '#12A98E',
    pageBg: '#08101B',
    timelineBorder: '#ffc886',
    timelineDotHighlight: '#ff8d06',
    timelineFloatingBg: '#222d3b',
    timelineHighlight: '#EDB358',
    timelineText: '#AEACA4',
  },
  easings: {
    cubicIn: 'cubic-bezier(0.32, 0, 0.67, 0)',
    cubicInOut: 'cubic-bezier(0.65, 0, 0.35, 1)',
    cubicOut: 'cubic-bezier(0.33, 1, 0.68, 1)',
  },
  fonts: {
    clashDisplay:
      "font-family: 'Clash Display', system-ui, apple-system, sans-serif !important;",
    matter:
      "font-family: 'Matter', system-ui, apple-system, sans-serif !important;",
    mono: 'font-family: monospace !important;',
    platform:
      "font-family: 'Platform', system-ui, apple-system, sans-serif !important;",
  },
  timings: {
    lg: '490ms',
    md: '320ms',
    sm: '200ms',
    xl: '620ms',
    xs: '125ms',
    xxl: '1240ms',
    xxxl: '2480ms',
  },
}
