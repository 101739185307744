import ArrowExternalTopRight from '@/assets/svg/arrow-external-top-right.inline.svg'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import {
  CopyrightNotice,
  ExternalLink,
  ExternalLinkText,
  FooterBackgroundImageContainer,
  FooterWrapper,
  LegalLinksContainer,
  LinksListContainer,
  LogoContainer,
  SocialIcon,
  SocialsListContainer,
} from './styles'

export const Footer: FC = () => {
  const pixelConstellation = '../../assets/images/pixel-constellation@2x.png'
  const footerStarfield = '../../assets/images/footer-starfield@3x.png'
  const footerStarfieldWide = '../../assets/images/footer-starfield-wide@3x.png'
  const logo10YearsFooter =
    '../../assets/images/pixelmatters-10-years-logo@2x.png'

  const externalLinks = [
    {
      href: 'https://www.youtube.com/playlist?list=PLzSN_HVtgRDDvQ3VWvxJeOILu-jR9dkQP',
      title: '10-year podcast',
    },
    {
      href: 'https://www.pixelmatters.com/blog',
      title: 'Blog',
    },
  ]

  const socialLinks = [
    {
      href: 'https://www.youtube.com/@Wearepixelmatters',
      title: 'YouTube',
    },
    {
      href: 'https://open.spotify.com/user/31ukgv5spxodqpjjx4f2qbimyvka?si=f3ce43fab9eb4da5',
      title: 'Spotify',
    },
    {
      href: 'https://www.instagram.com/pixelmatters',
      title: 'Instagram',
    },
    {
      href: 'https://x.com/pixelmatters_',
      title: 'X',
    },
    {
      href: 'https://www.linkedin.com/company/pixelmatters/',
      title: 'LinkedIn',
    },
  ]

  const currentYear = new Date().getFullYear()

  return (
    <FooterWrapper>
      <FooterBackgroundImageContainer>
        <StaticImage
          alt="Old Pixelmatters logo formed of tiny stars"
          className="pixel-constellation"
          layout="fixed"
          loading="lazy"
          src={pixelConstellation}
          width={34}
        />
        <StaticImage
          alt="Background starfield"
          className="bg-mobile"
          layout="fullWidth"
          loading="lazy"
          src={footerStarfield}
        />
        <StaticImage
          alt="Background starfield"
          className="bg-desktop"
          layout="fullWidth"
          loading="lazy"
          src={footerStarfieldWide}
        />
      </FooterBackgroundImageContainer>
      <LogoContainer>
        <Link to="/">
          <StaticImage
            alt="Pixelmatters 10 Years"
            height={47}
            layout="fixed"
            src={logo10YearsFooter}
            width={205}
          />
        </Link>
      </LogoContainer>
      <LinksListContainer>
        {externalLinks.map((link) => (
          <ExternalLink href={link.href} key={link.title} target="_blank">
            <ExternalLinkText>
              {link.title}
              <ArrowExternalTopRight />
            </ExternalLinkText>
          </ExternalLink>
        ))}
      </LinksListContainer>
      <SocialsListContainer>
        {socialLinks.map((social) => (
          <ExternalLink
            href={social.href}
            key={social.title}
            target="_blank"
            title={social.title}>
            <SocialIcon icon={social.title} />
          </ExternalLink>
        ))}
        <CopyrightNotice>© {currentYear} Pixelmatters</CopyrightNotice>
      </SocialsListContainer>
      <LegalLinksContainer>
        <ExternalLink
          href="https://pixelmatters.com/privacy-policy/"
          target="_blank">
          Privacy Policy
          <ArrowExternalTopRight />
        </ExternalLink>
        <ExternalLink
          href="https://pixelmatters.com/cookie-policy/"
          target="_blank">
          Cookie Policy
          <ArrowExternalTopRight />
        </ExternalLink>
        <CopyrightNotice>© {currentYear} Pixelmatters</CopyrightNotice>
      </LegalLinksContainer>
    </FooterWrapper>
  )
}
