import { styleVars } from '@/assets/styles/styleVars'
import { up } from 'styled-breakpoints'
import { css } from 'styled-components'

/* Optanon Cookie Consent bar */
export const OptanonCookieBarStyles = css`
  && {
    .optanon-alert-box-wrapper {
      ${styleVars.fonts.matter};

      bottom: 16px !important;
      left: 16px;
      width: calc(100% - 32px) !important;
      padding-top: 20px;
      background: rgba(31, 32, 33, 0.85);
      border-radius: 16px;
      box-shadow: 0 4px 4px 0 rgba(33, 37, 41, 0.4),
        0 20px 40px 0 rgba(33, 37, 41, 0.5),
        0 2px 0 0 rgba(255, 255, 255, 0.1) inset;
      backdrop-filter: blur(10px);

      ${up('lg')} {
        bottom: 20px !important;
        left: 22.5px;
        width: calc(100% - 45px) !important;
        height: 60px;
        padding: 0;
      }

      .optanon-alert-box-bg {
        display: flex;
        flex-flow: column !important;
        gap: 8px !important;

        ${up('lg')} {
          flex-flow: row !important;
          gap: 20px !important;
        }
      }

      .optanon-clearfix,
      .optanon-alert-box-bottom-top,
      .optanon-alert-box-logo {
        display: none;
      }

      .optanon-alert-box-body {
        display: flex;
        align-items: center;
        padding: 0 !important;
        margin: 0 !important;
      }

      /* Cookie bar text */
      #alert-box-message {
        padding: 0;
        margin: 0 0 0 20px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        a.banner-policy-link {
          padding-left: 3px;
          border: none !important;
          outline: none !important;
        }

        ${up('lg')} {
          display: flex;
          flex-flow: row;
          align-items: center;
          height: 60px;
        }
      }

      /* Cookie Policy link */
      .banner-policy-link {
        flex: 1 0 auto;
      }

      .optanon-alert-box-button-container {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
        display: flex;
        flex: 1 0 auto;
        gap: 16px;
        align-items: center;
        height: 60px;
        padding: 0 20px;
        margin: 0 0 8px !important;

        ${up('lg')} {
          flex-flow: row-reverse;
          gap: 12px;
          margin: 0 -2px 0 0 !important;
        }
      }

      .optanon-alert-box-button {
        padding: 0 !important;
        margin: 0 !important;

        /* Accept cookies */
        .accept-cookie-container {
          display: flex;
          max-width: 127px;
          height: 36px;
          padding: 0 12px;
          background: #d89e41;
          border: none;
          border-radius: 12px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35),
            0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 -2px 0 0 rgba(0, 0, 0, 0.6) inset;
          transition: background ${styleVars.timings.sm}
            ${styleVars.easings.cubicOut};

          &:hover {
            background: #e9ab49;
          }

          button {
            height: 32px;
            font-size: 14px;
            font-weight: 500;

            &::before {
              display: none;
            }
          }
        }
      }
    }

    /* Cookie settings */
    .optanon-alert-box-wrapper
      .optanon-button-more
      .optanon-alert-box-button-middle {
      padding: 0 !important;

      button {
        padding-bottom: 3px !important;
        font-size: 14px;
        font-weight: 500;
        border: none !important;
        transition: opacity ${styleVars.timings.sm}
          ${styleVars.easings.cubicOut};

        &::before {
          display: none;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`

export const OptanonCookieConsentOptionsStyles = css`
  #optanon fieldset label {
    padding-top: 4px !important;
    font-family: Matter !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #4a4a4a;
    text-align: left;
    text-indent: 43px !important;
  }
  #optanon fieldset input:checked + label {
    font-family: Matter !important;
    color: #009dac;
    background-color: #009dac !important;
  }
  #optanon fieldset input:checked + label::after {
    background-color: white !important;
    box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.1);
  }
  #optanon #optanon-popup-body p.header-3,
  #optanon #optanon-popup-body #optanon-vendor-consent-text {
    margin-left: 2px !important;
    font-family: Matter !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-stretch: normal;
    line-height: 1.75;
    color: #2b3138;
    letter-spacing: normal;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-body p.header-3,
    #optanon #optanon-popup-body #optanon-vendor-consent-text {
      margin-top: 14px !important;
      margin-bottom: 2px !important;
      margin-left: 12px !important;
    }
  }
  #optanon #optanon-popup-body div.optanon-main-info-text {
    margin-left: 2px !important;
    font-family: Matter !important;
    font-size: 13px !important;
    font-style: normal;
    font-weight: normal !important;
    font-stretch: normal;
    line-height: 1.5 !important;
    color: #2b3138 !important;
    letter-spacing: normal !important;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-body div.optanon-main-info-text {
      max-height: 250px !important;
      padding-right: 20px !important;
      margin-left: 13px !important;
      line-height: 20px !important;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    display: inline-block;
    font-family: Matter, 'Open Sans', Arial, 'Helvetica Neue', Helvetica,
      'Lucida Grande', sans-serif;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: #111314;
    background-image: none;
    box-shadow: none;
    transition: all 0.3s;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:hover,
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle
      button:hover {
      opacity: 0.85;
      transform: none;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:active,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:active {
    color: #fff;
    box-shadow: none;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:active,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:active {
    opacity: 1;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:disabled,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:disabled {
    opacity: 0.5;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    min-height: 36px;
    padding: 3px 17px 7px 16px;
    font-size: 15px;
    line-height: 1.67;
    border-radius: 18px;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-family: Matter;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.67;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent !important;
    background-image: none !important;
    border-radius: 18px;
    box-shadow: none;
    transition: all 0.3s;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    box-sizing: border-box;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a::before,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button::before {
    content: none !important;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover {
    background-color: transparent !important;
    background-image: none;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover {
      opacity: 0.85;
      transform: none;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:active {
    color: #fff;
    box-shadow: none;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:active {
    opacity: 1;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:disabled,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle--disabled {
    opacity: 0.5;
  }
  @media (min-width: 992px) {
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle:disabled:hover,
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle--disabled:hover {
      opacity: 0.5;
      transform: none;
    }
  }
  #optanon #optanon-menu li > .preference-menu-item a {
    font-family: Matter !important;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #2b3138;
    letter-spacing: normal;
  }
  #optanon #optanon-menu li .preference-menu-item button,
  #optanon #optanon-menu li .preference-menu-item a {
    font-weight: normal !important;
    color: #2b3138;
  }
  #optanon div#optanon-popup-bottom-logo::before,
  #optanon div#optanon-popup-bottom-logo::after {
    font-size: 12px;
    color: #111314;
    text-align: left;
    letter-spacing: -0.1px;
  }
  #optanon div#optanon-popup-bottom-logo::after {
    margin-left: 3px;
    color: #111314;
    text-decoration: underline;
    border: none;
    transition: opacity 0.3s;
    will-change: opacity;
  }
  #optanon .fieldset label {
    padding-top: 2px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #2b3138;
    text-align: left;
    text-indent: 43px !important;
    letter-spacing: -0.15px;
    background-color: #aaaeb3 !important;
    outline: none !important;
  }
  @media (min-width: 752px) {
    #optanon .fieldset label {
      text-indent: 43px !important;
    }
  }
  #optanon .fieldset {
    margin-top: -3px !important;
  }
  @media (min-width: 992px) {
    #optanon .fieldset {
      margin-top: 3px !important;
    }
  }
  #optanon .fieldset input:checked + label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    line-height: normal;
    color: #2b3138;
    letter-spacing: normal;
    background-color: #0063ff !important;
  }
  #optanon #optanon-popup-wrapper div.optanon-status-always-active p {
    margin-top: 0;
  }
  @media (min-width: 992px) {
    #optanon #optanon-popup-wrapper div.optanon-status-always-active p {
      margin-top: 6px !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500 !important;
      font-stretch: normal;
      line-height: normal;
      color: #2b3138 !important;
      letter-spacing: normal;
    }
  }
  #optanon #optanon-popup-wrapper .optanon-status {
    float: left;
  }
  @media (min-width: 992px) {
    #optanon #optanon-popup-wrapper .optanon-status {
      float: right;
    }
  }
  #optanon .vendor-header-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  @media (min-width: 992px) {
    #optanon .vendor-header-container {
      flex-direction: row !important;
      align-items: center !important;
    }
  }

  /** Footer **/
  #optanon div#optanon-popup-bottom {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 52px !important;
    border-top: 1px solid #e6e6e6 !important;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  @media (min-width: 768px) {
    #optanon div#optanon-popup-bottom {
      position: relative;
      flex-direction: row-reverse;
      justify-content: flex-start;
      padding-top: 0;
      padding-bottom: 7px !important;
    }
  }
  #optanon #optanon-popup-wrapper div.optanon-button-wrapper {
    margin: 9px 10px 0 0 !important;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-wrapper div.optanon-button-wrapper {
      margin: 7px 13px 0 0 !important;
    }
  }
  #optanon #optanon-popup-wrapper div.optanon-button-wrapper.optanon-allow-all {
    margin-right: 0 !important;
  }
  @media (min-width: 768px) {
    #optanon
      #optanon-popup-wrapper
      div.optanon-button-wrapper.optanon-allow-all {
      margin-right: 13px !important;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle {
    padding: 0;
    background-image: none;
    border-radius: 3px;
    transition: background 0.15s ease-in-out;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a {
    font-family: Matter !important;
    font-size: 0.9333rem;
    font-weight: 500 !important;
    color: white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0;
  }
  #optanon div#optanon-popup-bottom-logo {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (min-width: 768px) {
    #optanon div#optanon-popup-bottom-logo {
      bottom: 11px;
      left: 0;
      transform: none;
    }
  }
  #optanon div#optanon-popup-bottom-logo::before,
  #optanon div#optanon-popup-bottom-logo::after {
    top: 12px;
    font-family: Matter !important;
    font-size: 0.8667rem;
    color: #4a4a4a;
    text-align: left;
    letter-spacing: 0.1px;
  }
  #optanon div#optanon-popup-bottom-logo::before {
    margin-left: 10px;
  }
  #optanon div#optanon-popup-bottom-logo::after {
    margin-left: 3px;
    color: #2972ca;
    letter-spacing: -0.2px !important;
    border-bottom: none;
  }

  /** Menu **/
  #optanon #optanon-menu {
    position: relative;
    top: 0;
  }
  #optanon ul#optanon-menu li {
    background-color: #f0f1f2 !important;
    background-position: 16px 13px !important;
    border-bottom: 1px solid #d6d6d6;
  }
  #optanon #optanon-menu li.menu-item-about {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzJCMzEzOCI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIzIDEyYzQuNDE4IDAgOCAzLjU4MiA4IDhzLTMuNTgyIDgtOCA4LTgtMy41ODItOC04IDMuNTgyLTggOC04em0xLjMxNSA2LjE3MWgtLjY2OGwtMS45OTMuNjYzdi41NjdoLjc0MXYzLjc4NWwtLjEzLjE0Ni0uNjc5LjA1NlYyNGgzLjU3N3YtLjYxMmwtLjcwMi0uMDU2LS4xNDYtLjE0NlYxOC4xN3ptLS45NDMtMi42ODRjLS4zMTQgMC0uNTcuMS0uNzY2LjI5OC0uMTk3LjE5OC0uMjk1LjQ1Ny0uMjk1Ljc3NSAwIC4yOTYuMS41NDQuMy43NDQuMi4yLjQ0Ny4zLjczOS4zLjMxIDAgLjU2OC0uMTAzLjc3Mi0uMzA5LjIwNC0uMjA1LjMwNi0uNDcuMzA2LS43OTEgMC0uMjg1LS4xMDEtLjUyNS0uMzA0LS43MjItLjIwMi0uMTk2LS40NTMtLjI5NS0uNzUyLS4yOTV6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDM1IC0yNTQpIHRyYW5zbGF0ZSg0MjAgMTYxKSB0cmFuc2xhdGUoMCA4MSkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==');
  }
  #optanon #optanon-menu li.menu-item-necessary {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzJCMzEzOCI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMyAxMmM0LjQxOCAwIDggMy41ODIgOCA4cy0zLjU4MiA4LTggOC04LTMuNTgyLTgtOCAzLjU4Mi04IDgtOHptMy4yNDUgNS44MTJjLS4zOS0uMzktMS4wMjQtLjM5LTEuNDE0IDBsLTIuNjMyIDIuNjMxLS44NDUtLjg0NGMtLjM5LS4zOTEtMS4wMjQtLjM5MS0xLjQxNCAwLS4zOS4zOS0uMzkgMS4wMjMgMCAxLjQxNGwxLjU1MiAxLjU1MmMuMzkuMzkgMS4wMjMuMzkgMS40MTQgMGwzLjMzOS0zLjM0Yy4zOS0uMzkuMzktMS4wMjMgMC0xLjQxM3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00MzUgLTMxMSkgdHJhbnNsYXRlKDQyMCAxNjEpIHRyYW5zbGF0ZSgwIDgxKSB0cmFuc2xhdGUoMCA1NykiLz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=');
  }
  #optanon #optanon-menu li.menu-item-performance {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzJCMzEzOCI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMyAyOGMtNC40MTggMC04LTMuNTgyLTgtOHMzLjU4Mi04IDgtOCA4IDMuNTgyIDggOC0zLjU4MiA4LTggOHptLTMtMTFjLjU1MiAwIDEgLjQ0OCAxIDF2NGg1Yy41NTIgMCAxIC40NDggMSAxcy0uNDQ4IDEtMSAxaC02Yy0uNTUyIDAtMS0uNDQ4LTEtMXYtNWMwLS41NTIuNDQ4LTEgMS0xem03LjQ4OC40ODZjLjI4NC4zMDEuMjcuNzc2LS4wMzIgMS4wNmwtMi4wNDUgMS45MjVjLS4zNC4zMi0uODg3LjI1NS0xLjE0Mi0uMTM2bC0uNzQ4LTEuMTQ0LTEuMTQ5IDEuMTczYy0uMjkuMjk2LS43NjQuMy0xLjA2LjAxLS4yOTYtLjI4OS0uMzAxLS43NjQtLjAxMS0xLjA2bDEuODAxLTEuODM5Yy4zMzUtLjM0Mi45MDEtLjI4NyAxLjE2NC4xMTRsLjc2OSAxLjE3NiAxLjM5My0xLjMxMWMuMzAyLS4yODQuNzc2LS4yNyAxLjA2LjAzMnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00MzUgLTM2NykgdHJhbnNsYXRlKDQyMCAxNjEpIHRyYW5zbGF0ZSgwIDgxKSB0cmFuc2xhdGUoMCAxMTMpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K');
  }
  #optanon #optanon-menu li.menu-item-advertising {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzJCMzEzOCI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMyAxM2M0LjQxOCAwIDggMy41ODIgOCA4cy0zLjU4MiA4LTggOC04LTMuNTgyLTgtOCAzLjU4Mi04IDgtOHptMi4xOTQgNC42NTVoLTEuMjc4bC0xLjU4NSAzLjcyMi0xLjUzNS0zLjYwN2gtLjg1MmwtMS44IDQuMjNoLjk0MmwuMzg0LS45NDJoMS43NzZsLjM4NC45NDJoLjQzNmwtLjg1IDJoMS40MTNsLjU3Ni0xLjQxM2gyLjY2NEwyNi40NDUgMjRoMS40NDlsLTIuNy02LjM0NXptLS42NTcgMS42NjVsLjgzNyAyLjA0M0gyMy43bC44MzctMi4wNDN6bS00LjE3OS0uNDRsLjU1OCAxLjM2MkgxOS44bC41NTgtMS4zNjJ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDM1IC00MjQpIHRyYW5zbGF0ZSg0MjAgMTYxKSB0cmFuc2xhdGUoMCA4MSkgdHJhbnNsYXRlKDAgMTY5KSIvPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==');
  }
  #optanon #optanon-menu li.menu-item-moreinfo {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzJCMzEzOCI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMyAxM2M0LjQxOCAwIDggMy41ODIgOCA4cy0zLjU4MiA4LTggOC04LTMuNTgyLTgtOCAzLjU4Mi04IDgtOHptMSA0aC00djhoNnYtNmwtMi0yem0tLjYyMSAxLjVsMS4xMjEgMS4xMjFWMjMuNWgtM3YtNWgxLjg3OXpNMjQgMjJoLTJ2MWgydi0xem0wLTJoLTJ2MWgydi0xeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQzNSAtNTA2KSB0cmFuc2xhdGUoNDIwIDE2MSkgdHJhbnNsYXRlKDAgODEpIHRyYW5zbGF0ZSgwIDI1MSkiLz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=');
  }
  #optanon #optanon-menu li > .preference-menu-item {
    padding: 10px 0 14px 29px !important;
  }
  #optanon #optanon-menu li > h2 {
    margin-right: 39px;
  }

  #optanon #optanon-menu li .preference-menu-item button,
  #optanon #optanon-menu li .preference-menu-item a {
    line-height: 1.37;
  }

  #optanon #optanon-menu li.menu-item-selected > .preference-menu-item a {
    font-weight: 700 !important;
  }

  /** Body **/
  #optanon div#optanon-popup-body h3 {
    width: 100%;
    padding-top: 23px;
    margin: 0 !important;
    font-size: 1.0667rem;
    color: #4a4a4a;
    text-align: left;
    letter-spacing: -0.2px;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-body h3 {
      width: auto;
      padding: 1px 0 0 14px !important;
      margin: 0 !important;
    }
  }
  #optanon div#optanon-popup-body div#optanon-main-info-text {
    display: block;
    padding: 8px 0 0 0;
    margin: 0;
    clear: both;
    font-size: 0.9333rem;
    font-weight: 300;
    line-height: 1.86;
    letter-spacing: 0.1px;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-body div#optanon-main-info-text {
      padding: 9px 13px 0;
      letter-spacing: -0.16px;
    }
  }
  #optanon div#optanon-popup-more-info-bar {
    position: relative;
    top: -1px;
  }
  @media (min-width: 768px) {
    #optanon div#optanon-popup-more-info-bar {
      position: absolute;
      top: 22px !important;
      right: 0 !important;
    }
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-more-info-bar {
      top: 17px !important;
    }
  }
  @media (min-width: 1024px) {
    #optanon div#optanon-popup-more-info-bar {
      right: 79px !important;
    }
  }
  #optanon #optanon-popup-body-right {
    padding: 5px 10px 18px 14px !important;
  }
  #optanon #optanon-popup-body .legacy-preference-banner-title,
  #optanon #optanon-popup-body .legacy-preference-banner-title.h2 {
    display: inline-block !important;
    margin: 15px 0 0 2px !important;
    font-family: Matter !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: normal !important;
    font-stretch: normal;
    line-height: 1.79 !important;
    color: #2b3138;
    letter-spacing: normal !important;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-body .legacy-preference-banner-title,
    #optanon #optanon-popup-body .legacy-preference-banner-title.h2 {
      margin: -3px 0 0 13px !important;
    }
  }
  @media (min-width: 992px) {
    #optanon #optanon-popup-body .legacy-preference-banner-title,
    #optanon #optanon-popup-body .legacy-preference-banner-title.h2 {
      margin: -6px 0 0 12px !important;
      font-size: 19px !important;
    }
  }
  #optanon #optanon-popup-more-info-bar p {
    padding-top: 11px;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #4a4a4a;
    text-align: left !important;
  }
  #optanon #optanon-popup-wrapper div.optanon-status-always-active {
    width: 110px;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-wrapper div.optanon-status-always-active {
      position: absolute;
      top: -1px;
      right: 30px;
    }
  }
  @media (min-width: 1024px) {
    #optanon #optanon-popup-wrapper div.optanon-status-always-active {
      top: -2px;
      right: -67px;
    }
  }
  #optanon #optanon-popup-wrapper div.optanon-status-always-active p {
    padding-top: 0 !important;
    font-family: Matter !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
  #optanon,
  #optanon *,
  #optanon div,
  #optanon span,
  #optanon ul,
  #optanon li,
  #optanon a,
  #optanon p {
    font-family: Matter !important;
  }
  @media (max-width: 751px) {
    #optanon {
      display: flex;
      flex-direction: column;
    }
  }

  /** Header **/
  #optanon div#optanon-popup-wrapper {
    top: 19px !important;
    margin-top: 50px !important;
    box-shadow: 0 -2px 10px 0 rgba(0, 36, 50, 0.33);
  }
  @media (max-width: 768px) {
    #optanon div#optanon-popup-wrapper {
      top: calc(50% + 30px) !important;
      height: calc(100% - 160px);
      margin: 0 !important;
      margin-top: 50px !important;
      overflow-y: scroll;
      border-radius: 2px !important;
      transform: translateY(-50%) !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    #optanon div#optanon-popup-wrapper {
      height: calc(100% - 70px);
      overflow-y: scroll;
      -ms-overflow-style: none;

      /* IE and Edge */
      scrollbar-width: none;

      /* Firefox */
    }
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-wrapper {
      margin-top: 91px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    #optanon div#optanon-popup-wrapper::-webkit-scrollbar {
      display: none;
    }
  }
  #optanon,
  #optanon.modern #optanon-popup-top,
  #optanon.modern #optanon-popup-wrapper #optanon-popup-top,
  #optanon.modern #optanon-popup-body-left-shading,
  #optanon.modern #optanon-popup-wrapper #optanon-popup-body-left-shading,
  #optanon #optanon-popup-bottom,
  #optanon #optanon-popup-wrapper #optanon-popup-bottom {
    background-color: #fff !important;
  }
  #optanon.modern #optanon-popup-body-left-shading {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  #optanon #optanon-branding-top-logo {
    position: absolute !important;
    top: -65px !important;
    left: calc(50% + 1px) !important;
    max-width: 135px !important;
    background-position: 0% 50% !important;
    background-size: 135px !important;
    transform: translate(-50%);
  }
  @media (max-width: 991px) {
    #optanon #optanon-branding-top-logo {
      min-width: 135px;
    }
  }
  @media (min-width: 768px) {
    #optanon #optanon-branding-top-logo {
      left: 21px !important;
      max-width: 117px !important;
      background-size: 117px !important;
      transform: translateY(-2px) !important;
    }
  }
  @media (min-width: 768px) {
    #optanon-branding-top-logo::after {
      position: absolute;
      top: -4px;
      right: -5px;
      width: 1px;
      height: 60px;
      content: '';
      background: #e6e6e6;
    }
  }
  @media (min-width: 992px) {
    #optanon-branding-top-logo::after {
      right: -22px;
    }
  }
  #optanon div#optanon-popup-top {
    border-bottom: 1px solid #e6e6e6;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  #optanon-popup-top a,
  #optanon #optanon-popup-top .optanon-close {
    position: fixed !important;
    top: 21px !important;
    right: 20px !important;
    width: 15px !important;
    height: 15px !important;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjFweCIgaGVpZ2h0PSIyMXB4IiB2aWV3Qm94PSIwIDAgMjEgMjEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjUgKDY3NDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5pY29uLWNyb3NzPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Imljb24tY3Jvc3MiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSI5LjAzNiAxMC40NSAwIDEuNDE0IDEuNDE0IDAgMTAuNDUgOS4wMzYgMTkuNDg1IDAgMjAuOSAxLjQxNCAxMS44NjUgMTAuNDUgMjAuOSAxOS40ODUgMTkuNDg2IDIwLjg5OSAxMC40NTEgMTEuODY0IDEuNDE1IDIwLjg5OSAwIDE5LjQ4NSI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+') !important;
    background-size: 15px 15px !important;
    outline: none;
    box-shadow: none !important;
    transition: opacity 0.3s ease-in-out;
    transform: none !important;
  }
  @media (max-width: 991px) {
    #optanon-popup-top a,
    #optanon #optanon-popup-top .optanon-close {
      position: relative !important;
      top: 20px !important;
      right: 20px !important;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4gICAgPHBhdGggZmlsbD0iIzhCOEZBMSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNOCA1Ljg4Mmw1LjU4OS01LjU5YTEgMSAwIDAgMSAxLjQxNCAwbC43MDQuNzA1YTEgMSAwIDAgMSAwIDEuNDE0TDEwLjExOCA4bDUuNTkgNS41ODlhMSAxIDAgMCAxIDAgMS40MTRsLS43MDUuNzA0YTEgMSAwIDAgMS0xLjQxNCAwTDggMTAuMTE4bC01LjU4OSA1LjU5YTEgMSAwIDAgMS0xLjQxNCAwbC0uNzA0LS43MDVhMSAxIDAgMCAxIDAtMS40MTRMNS44ODIgOCAuMjkyIDIuNDExYTEgMSAwIDAgMSAwLTEuNDE0TC45OTguMjkzYTEgMSAwIDAgMSAxLjQxNCAwTDggNS44ODJ6Ii8+PC9zdmc+') !important;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  @media (min-width: 992px) {
    #optanon-popup-top a,
    #optanon #optanon-popup-top .optanon-close {
      top: 43px !important;
      right: 42px !important;
      width: 20px !important;
      height: 20px !important;
      background-size: 20px 20px !important;
    }
  }
  #optanon-popup-top a:hover,
  #optanon #optanon-popup-top .optanon-close:hover {
    opacity: 0.5;
  }

  #optanon-cookie-policy {
    padding-top: 6px;
    padding-bottom: 58px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy {
      padding-top: 5px;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 21px !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-name {
    margin-bottom: 12px !important;
    font-family: Matter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    line-height: 1.45;
    color: #2b3138;
    text-align: center;
    letter-spacing: normal;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-group-name {
      margin-bottom: 21px !important;
      font-size: 30px;
      line-height: 1.27;
      text-align: left;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-description {
    margin: 0 0 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-stretch: normal;
    line-height: 1.81;
    color: #2b3138;
    letter-spacing: normal;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-group-description {
      font-size: 19px;
      line-height: 1.79;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-description:empty {
    display: none;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table tr th {
    padding-bottom: 5px;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table tr {
    border-bottom: 1px solid #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table tr:last-child {
    border-bottom: 1px solid #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup {
    border-bottom: solid 1px #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup::after {
    display: block;
    clear: both;
    content: '';
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list {
    padding-left: 19px;
    margin: 0 0 20px;
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list li {
    padding-left: 17px;
  }

  @media (min-width: 1024px) {
    #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list li {
      padding-left: 15px;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list li::marker {
    content: '•';
  }

  /** table **/
  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table {
    margin-top: 23px;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table
    .optanon-cookie-policy-right {
    width: 50%;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table
      .optanon-cookie-policy-right {
      width: auto;
      min-width: 195px;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table-header {
    border-bottom: solid 1px #e6e6e6;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-header
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-header
    .optanon-cookie-policy-right {
    padding: 11px 14px 10px 5px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table-header
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table-header
      .optanon-cookie-policy-right {
      width: auto;
      padding: 11px 14px 10px 20px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-header
    .optanon-cookie-policy-right {
    padding-left: 15px !important;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-right {
    padding: 7px 10px 5px 5px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-right {
      padding: 9px 10px 7px 5px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left {
    padding-left: 0;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table-column-header {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: black;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-table-column-header {
      font-size: 18px;
      line-height: 28px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-column-header:first-of-type {
    margin-bottom: 0;
  }

  #optanon-cookie-policy .optanon-cookie-policy-left {
    height: 100%;
    padding: 5px 0;
    border-color: #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-right {
    padding-left: 15px !important;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-right {
    padding: 7px 10px 5px 5px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-right {
      padding: 9px 10px 7px 5px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left {
    padding-left: 0;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-subgroup-name {
    margin-bottom: 0 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: black;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-subgroup-name {
      font-size: 18px;
      line-height: 38px;
      color: black;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-subgroup-name:first-of-type {
    margin-top: 0;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list {
    padding: 0 0 0 15px;
    margin: 0;
    word-break: break-word;
    list-style-type: disc;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list li {
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list {
      padding-left: 0;
      margin-left: 20px;
      word-break: normal;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list li,
  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list p {
    padding-bottom: 0 !important;
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list li,
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list p {
      font-size: 18px;
      line-height: 34px;
      letter-spacing: -0.19px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-cookies-list
    li::before {
    content: none;
  }

  .optanon-show-settings-popup-wrapper {
    display: inline-block;
    height: auto;
    margin-top: 19px;
  }

  #optanon-show-settings-popup li::before {
    content: none !important;
  }

  .tpl-legal .optanon-show-settings-popup-wrapper {
    margin-top: 30px;
    vertical-align: top;
  }

  .tpl-legal .optanon-show-settings-popup-wrapper a.optanon-show-settings,
  .tpl-legal .optanon-show-settings-popup-wrapper button.optanon-show-settings {
    position: relative;
    top: -6px;
    text-decoration: none;
  }

  /** Controllers **/
  #optanon fieldset label {
    padding-top: 4px !important;
    font-family: Matter !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #4a4a4a;
    text-align: left;
    text-indent: 43px !important;
  }
  #optanon fieldset input:checked + label {
    font-family: Matter !important;
    color: #009dac;
    background-color: #009dac !important;
  }
  #optanon fieldset input:checked + label::after {
    background-color: white !important;
    box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.1);
  }
  #optanon #optanon-popup-body p.header-3,
  #optanon #optanon-popup-body #optanon-vendor-consent-text {
    margin-left: 2px !important;
    font-family: Matter !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-stretch: normal;
    line-height: 1.75;
    color: #2b3138;
    letter-spacing: normal;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-body p.header-3,
    #optanon #optanon-popup-body #optanon-vendor-consent-text {
      margin-top: 14px !important;
      margin-bottom: 2px !important;
      margin-left: 12px !important;
    }
  }
  #optanon #optanon-popup-body div.optanon-main-info-text {
    margin-left: 2px !important;
    font-family: Matter !important;
    font-size: 13px !important;
    font-style: normal;
    font-weight: normal !important;
    font-stretch: normal;
    line-height: 1.5 !important;
    color: #2b3138 !important;
    letter-spacing: normal !important;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-body div.optanon-main-info-text {
      max-height: 250px !important;
      padding-right: 20px !important;
      margin-left: 13px !important;
      line-height: 20px !important;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    display: inline-block;
    font-family: Matter, 'Open Sans', Arial, 'Helvetica Neue', Helvetica,
      'Lucida Grande', sans-serif;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: #111314;
    background-image: none;
    box-shadow: none;
    transition: all 0.3s;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:hover,
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle
      button:hover {
      opacity: 0.85;
      transform: none;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:active,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:active {
    color: #fff;
    box-shadow: none;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:active,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:active {
    opacity: 1;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:disabled,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:disabled {
    opacity: 0.5;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    min-height: 36px;
    padding: 3px 17px 7px 16px;
    font-size: 15px;
    line-height: 1.67;
    border-radius: 18px;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-family: Matter;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.67;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent !important;
    background-image: none !important;
    border-radius: 18px;
    box-shadow: none;
    transition: all 0.3s;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    box-sizing: border-box;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a::before,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button::before {
    content: none !important;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover {
    background-color: transparent !important;
    background-image: none;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover {
      opacity: 0.85;
      transform: none;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:active {
    color: #fff;
    box-shadow: none;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:active {
    opacity: 1;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:disabled,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle--disabled {
    opacity: 0.5;
  }
  @media (min-width: 992px) {
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle:disabled:hover,
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle--disabled:hover {
      opacity: 0.5;
      transform: none;
    }
  }
  #optanon #optanon-menu li > .preference-menu-item a {
    font-family: Matter !important;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #2b3138;
    letter-spacing: normal;
  }
  #optanon #optanon-menu li .preference-menu-item button,
  #optanon #optanon-menu li .preference-menu-item a {
    font-weight: normal !important;
    color: #2b3138;
  }
  #optanon div#optanon-popup-bottom-logo::before,
  #optanon div#optanon-popup-bottom-logo::after {
    font-size: 12px;
    color: #111314;
    text-align: left;
    letter-spacing: -0.1px;
  }
  #optanon div#optanon-popup-bottom-logo::after {
    margin-left: 3px;
    color: #111314;
    text-decoration: underline;
    border: none;
    transition: opacity 0.3s;
    will-change: opacity;
  }
  #optanon .fieldset label {
    padding-top: 2px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #2b3138;
    text-align: left;
    text-indent: 43px !important;
    letter-spacing: -0.15px;
    background-color: #aaaeb3 !important;
    outline: none !important;
  }
  @media (min-width: 752px) {
    #optanon .fieldset label {
      text-indent: 43px !important;
    }
  }
  #optanon .fieldset {
    margin-top: -3px !important;
  }
  @media (min-width: 992px) {
    #optanon .fieldset {
      margin-top: 3px !important;
    }
  }
  #optanon .fieldset input:checked + label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    line-height: normal;
    color: #2b3138;
    letter-spacing: normal;
    background-color: #0063ff !important;
  }
  #optanon #optanon-popup-wrapper div.optanon-status-always-active p {
    margin-top: 0;
  }
  @media (min-width: 992px) {
    #optanon #optanon-popup-wrapper div.optanon-status-always-active p {
      margin-top: 6px !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500 !important;
      font-stretch: normal;
      line-height: normal;
      color: #2b3138 !important;
      letter-spacing: normal;
    }
  }
  #optanon #optanon-popup-wrapper .optanon-status {
    float: left;
  }
  @media (min-width: 992px) {
    #optanon #optanon-popup-wrapper .optanon-status {
      float: right;
    }
  }
  #optanon .vendor-header-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  @media (min-width: 992px) {
    #optanon .vendor-header-container {
      flex-direction: row !important;
      align-items: center !important;
    }
  }
  a:focus-visible,
  button:focus-visible,
  div:focus-visible {
    outline: 1px solid #0063ff;
  }

  /** Controllers **/
  #optanon fieldset label {
    padding-top: 4px !important;
    font-family: Matter !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #4a4a4a;
    text-align: left;
    text-indent: 43px !important;
  }
  #optanon fieldset input:checked + label {
    font-family: Matter !important;
    color: #009dac;
    background-color: #009dac !important;
  }
  #optanon fieldset input:checked + label::after {
    background-color: white !important;
    box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.1);
  }
  #optanon #optanon-popup-body p.header-3,
  #optanon #optanon-popup-body #optanon-vendor-consent-text {
    margin-left: 2px !important;
    font-family: Matter !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-stretch: normal;
    line-height: 1.75;
    color: #2b3138;
    letter-spacing: normal;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-body p.header-3,
    #optanon #optanon-popup-body #optanon-vendor-consent-text {
      margin-top: 14px !important;
      margin-bottom: 2px !important;
      margin-left: 12px !important;
    }
  }
  #optanon #optanon-popup-body div.optanon-main-info-text {
    margin-left: 2px !important;
    font-family: Matter !important;
    font-size: 13px !important;
    font-style: normal;
    font-weight: normal !important;
    font-stretch: normal;
    line-height: 1.5 !important;
    color: #2b3138 !important;
    letter-spacing: normal !important;
  }
  @media (min-width: 768px) {
    #optanon #optanon-popup-body div.optanon-main-info-text {
      max-height: 250px !important;
      padding-right: 20px !important;
      margin-left: 13px !important;
      line-height: 20px !important;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    display: inline-block;
    font-family: Matter, 'Open Sans', Arial, 'Helvetica Neue', Helvetica,
      'Lucida Grande', sans-serif;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: #111314;
    background-image: none;
    box-shadow: none;
    transition: all 0.3s;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:hover,
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle
      button:hover {
      opacity: 0.85;
      transform: none;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:active,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:active {
    color: #fff;
    box-shadow: none;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:active,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:active {
    opacity: 1;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a:disabled,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button:disabled {
    opacity: 0.5;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    min-height: 36px;
    padding: 3px 17px 7px 16px;
    font-size: 15px;
    line-height: 1.67;
    border-radius: 18px;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-family: Matter;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.67;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent !important;
    background-image: none !important;
    border-radius: 18px;
    box-shadow: none;
    transition: all 0.3s;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle button {
    box-sizing: border-box;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle a::before,
  #optanon
    div#optanon-popup-wrapper
    .optanon-white-button-middle
    button::before {
    content: none !important;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover {
    background-color: transparent !important;
    background-image: none;
  }
  @media (min-width: 992px) {
    #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover {
      opacity: 0.85;
      transform: none;
    }
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:hover,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:active {
    color: #fff;
    box-shadow: none;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:focus,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:active {
    opacity: 1;
  }
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle:disabled,
  #optanon div#optanon-popup-wrapper .optanon-white-button-middle--disabled {
    opacity: 0.5;
  }
  @media (min-width: 992px) {
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle:disabled:hover,
    #optanon
      div#optanon-popup-wrapper
      .optanon-white-button-middle--disabled:hover {
      opacity: 0.5;
      transform: none;
    }
  }
  #optanon #optanon-menu li > .preference-menu-item a {
    font-family: Matter !important;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #2b3138;
    letter-spacing: normal;
  }
  #optanon #optanon-menu li .preference-menu-item button,
  #optanon #optanon-menu li .preference-menu-item a {
    font-weight: normal !important;
    color: #2b3138;
  }
  #optanon div#optanon-popup-bottom-logo::before,
  #optanon div#optanon-popup-bottom-logo::after {
    font-size: 12px;
    color: #111314;
    text-align: left;
    letter-spacing: -0.1px;
  }
  #optanon div#optanon-popup-bottom-logo::after {
    margin-left: 3px;
    color: #111314;
    text-decoration: underline;
    border: none;
    transition: opacity 0.3s;
    will-change: opacity;
  }
  #optanon .fieldset label {
    padding-top: 2px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #2b3138;
    text-align: left;
    text-indent: 43px !important;
    letter-spacing: -0.15px;
    background-color: #aaaeb3 !important;
    outline: none !important;
  }
  @media (min-width: 752px) {
    #optanon .fieldset label {
      text-indent: 43px !important;
    }
  }
  #optanon .fieldset {
    margin-top: -3px !important;
  }
  @media (min-width: 992px) {
    #optanon .fieldset {
      margin-top: 3px !important;
    }
  }
  #optanon .fieldset input:checked + label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    line-height: normal;
    color: #2b3138;
    letter-spacing: normal;
    background-color: #0063ff !important;
  }
  #optanon #optanon-popup-wrapper div.optanon-status-always-active p {
    margin-top: 0;
  }
  @media (min-width: 992px) {
    #optanon #optanon-popup-wrapper div.optanon-status-always-active p {
      margin-top: 6px !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500 !important;
      font-stretch: normal;
      line-height: normal;
      color: #2b3138 !important;
      letter-spacing: normal;
    }
  }
  #optanon #optanon-popup-wrapper .optanon-status {
    float: left;
  }
  @media (min-width: 992px) {
    #optanon #optanon-popup-wrapper .optanon-status {
      float: right;
    }
  }
  #optanon .vendor-header-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  @media (min-width: 992px) {
    #optanon .vendor-header-container {
      flex-direction: row !important;
      align-items: center !important;
    }
  }
  #optanon-cookie-policy {
    padding-top: 6px;
    padding-bottom: 58px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy {
      padding-top: 5px;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 21px !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-name {
    margin-bottom: 12px !important;
    font-family: Matter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    line-height: 1.45;
    color: #2b3138;
    text-align: center;
    letter-spacing: normal;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-group-name {
      margin-bottom: 21px !important;
      font-size: 30px;
      line-height: 1.27;
      text-align: left;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-description {
    margin: 0 0 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-stretch: normal;
    line-height: 1.81;
    color: #2b3138;
    letter-spacing: normal;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-group-description {
      font-size: 19px;
      line-height: 1.79;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-description:empty {
    display: none;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table tr th {
    padding-bottom: 5px;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table tr {
    border-bottom: 1px solid #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table tr:last-child {
    border-bottom: 1px solid #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup {
    border-bottom: solid 1px #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup::after {
    display: block;
    clear: both;
    content: '';
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list {
    padding-left: 19px;
    margin: 0 0 20px;
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list li {
    padding-left: 17px;
  }

  @media (min-width: 1024px) {
    #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list li {
      padding-left: 15px;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-group-cookies-list li::marker {
    content: '•';
  }

  /** table **/
  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table {
    margin-top: 23px;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table
    .optanon-cookie-policy-right {
    width: 50%;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table
      .optanon-cookie-policy-right {
      width: auto;
      min-width: 195px;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table-header {
    border-bottom: solid 1px #e6e6e6;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-header
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-header
    .optanon-cookie-policy-right {
    padding: 11px 14px 10px 5px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table-header
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup-table-header
      .optanon-cookie-policy-right {
      width: auto;
      padding: 11px 14px 10px 20px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-header
    .optanon-cookie-policy-right {
    padding-left: 15px !important;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-right {
    padding: 7px 10px 5px 5px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-right {
      padding: 9px 10px 7px 5px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left {
    padding-left: 0;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-table-column-header {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: black;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-table-column-header {
      font-size: 18px;
      line-height: 28px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-table-column-header:first-of-type {
    margin-bottom: 0;
  }

  #optanon-cookie-policy .optanon-cookie-policy-left {
    height: 100%;
    padding: 5px 0;
    border-color: #e6e6e6 !important;
  }

  #optanon-cookie-policy .optanon-cookie-policy-right {
    padding-left: 15px !important;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left,
  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-right {
    padding: 7px 10px 5px 5px;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-left,
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-right {
      padding: 9px 10px 7px 5px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-left {
    padding-left: 0;
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-subgroup-name {
    margin-bottom: 0 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: black;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy
      .optanon-cookie-policy-subgroup:not(
        .optanon-cookie-policy-subgroup-table-column-header
      )
      .optanon-cookie-policy-subgroup-name {
      font-size: 18px;
      line-height: 38px;
      color: black;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup:not(
      .optanon-cookie-policy-subgroup-table-column-header
    )
    .optanon-cookie-policy-subgroup-name:first-of-type {
    margin-top: 0;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list {
    padding: 0 0 0 15px;
    margin: 0;
    word-break: break-word;
    list-style-type: disc;
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list li {
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list {
      padding-left: 0;
      margin-left: 20px;
      word-break: normal;
    }
  }

  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list li,
  #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list p {
    padding-bottom: 0 !important;
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list li,
    #optanon-cookie-policy .optanon-cookie-policy-subgroup-cookies-list p {
      font-size: 18px;
      line-height: 34px;
      letter-spacing: -0.19px;
    }
  }

  #optanon-cookie-policy
    .optanon-cookie-policy-subgroup-cookies-list
    li::before {
    content: none;
  }

  .optanon-show-settings-popup-wrapper {
    display: inline-block;
    height: auto;
    margin-top: 19px;
  }

  #optanon-show-settings-popup li::before {
    content: none !important;
  }
`
