import HeroBorder9Slice from '@/assets/images/hero-border-9slice@2x.webp'
import { styleVars } from '@/assets/styles/styleVars'
import iconSocialInstagram from '@/assets/svg/icon-social-instagram.svg'
import iconSocialLinkedIn from '@/assets/svg/icon-social-linkedin.svg'
import iconSocialSpotify from '@/assets/svg/icon-social-spotify.svg'
import iconSocialX from '@/assets/svg/icon-social-x.svg'
import iconSocialYouTube from '@/assets/svg/icon-social-youtube.svg'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

export const FooterWrapper = styled.footer`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 300px;
  margin-bottom: 15px;

  ${up('md')} {
    flex-flow: row wrap;
    align-items: flex-end;
    width: 100%;
    height: unset;
    padding: 25px 60px;
    margin-bottom: 20px;
  }
`
export const FooterBackgroundImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 15px;
  z-index: -1;
  display: block;
  order: 0;
  width: calc(100% - 30px);
  height: 100%;
  max-height: 393px;
  overflow: hidden;
  border-radius: 0 0 36px 36px;

  ${up('md')} {
    left: 20px;
    width: calc(100% - 40px);
    height: 228px;
  }

  /* Gatsby image structure - lower layer */
  > div {
    z-index: -3;
    height: 100%;
  }

  .bg-mobile,
  .bg-desktop {
    position: absolute !important;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }

  .bg-mobile {
    height: 394px;

    ${up('md')} {
      display: none;
    }
  }

  .bg-desktop {
    display: none;

    ${up('md')} {
      display: block;
      height: 227px;
    }
  }

  /* Pixel constellation */
  .pixel-constellation {
    top: 50px;
    left: 72vw;
    width: 34px;
    height: 45px;

    ${up('md')} {
      top: 99px;
      left: 35.6vw;
    }
  }

  /* 9-slice blurred border - middle layer */
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    display: block;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    content: '';
    border: 1px solid;
    border-radius: 0 0 36px 36px;
    border-image-source: url(${HeroBorder9Slice});
    border-image-slice: fill 72;
    border-image-width: 36px;
    mask-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  /* Darkener gradient - upper layer */
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(
      0deg,
      rgba(5, 6, 14, 0) 55%,
      rgba(8, 16, 27, 1) 100%
    );
  }
`
export const LogoContainer = styled.div`
  position: relative;
  top: -2px;
  left: -1px;
  order: 1;
  width: 168px;
  height: 64px;
  padding: 0 0 26px 3px;
  transform: scale(0.81);
  transform-origin: top left;

  ${up('md')} {
    position: relative;
    top: 7px;
    left: -1px;
    height: 39px;
    padding: 0;
  }
`
export const LinksListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: center;
  order: 2;
  padding-left: 12px;
  margin-bottom: 21px;

  ${up('md')} {
    flex-direction: row;
    gap: 20px;
    align-items: center;
    height: 36px;
    padding: 4px 0 0 28px;
    margin-bottom: 0;
  }
`
export const ExternalLink = styled.a`
  transition: opacity ${styleVars.timings.sm} ${styleVars.easings.cubicOut};

  ${up('md')} {
    &:hover,
    &:active {
      opacity: 0.8;

      svg {
        transition: transform ${styleVars.timings.sm}
          ${styleVars.easings.cubicOut};
        transform: translate(1.5px, -1.5px);
      }
    }
  }
`
export const ExternalLinkText = styled.span`
  display: flex;
  align-items: flex-end;
  height: 13px;
  font-size: 16px;
  font-weight: 500;
  color: white;

  ${up('md')} {
    font-size: 14px;
  }

  svg {
    position: relative;
    top: -4px;
    left: 4px;
    transition: transform ${styleVars.timings.sm} ${styleVars.easings.cubicOut};

    path {
      stroke: ${styleVars.colors.externalArrowBorder};
    }

    ${up('md')} {
      top: -2px;
      left: 4px;
      width: 11px;
      height: 11px;
    }
  }
`
export const CopyrightNotice = styled.span`
  font-size: 14px;
  line-height: 16.8px;
  transform: translate(-2px, -1px);

  ${up('md')} {
    margin-left: auto;
    font-size: 12px;
    line-height: 14.4px;
    transform: translate(0, 0);
  }
`
export const SocialsListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  justify-content: center;
  order: 4;
  padding: 27px 0 0 4px;

  ${up('md')} {
    gap: 10.2px;
    height: 30px;
    padding: 0;
    margin: 0 -4px -1px auto;
  }

  ${CopyrightNotice} {
    width: 100%;
    text-align: center;

    ${up('md')} {
      display: none;
      text-align: right;
    }
  }
`
export const SocialIcon = styled.div<{ icon: string }>`
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;

  ${({ icon }) => {
    switch (icon) {
      case 'YouTube':
        return css`
          background-image: url('${iconSocialYouTube}');
        `
      case 'Spotify':
        return css`
          background-image: url('${iconSocialSpotify}');
        `
      case 'Instagram':
        return css`
          background-image: url('${iconSocialInstagram}');
        `
      case 'X':
        return css`
          background-image: url('${iconSocialX}');
        `
      case 'LinkedIn':
        return css`
          background-image: url('${iconSocialLinkedIn}');
        `
    }
  }}
`

export const LegalLinksContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0 20px;
  order: 3;
  padding: 33px 0 0;
  margin: 0 0 0 -10px;

  ${up('md')} {
    position: relative;
    top: 6px;
    gap: 0 6px;
    order: 5;
    width: 100%;
    padding: 14px 0 0 0;
    margin: 6px 0 0 0;
  }

  /* Top border */
  &::before {
    position: absolute;
    top: 0;
    left: 16px;
    z-index: 0;
    display: block;
    width: calc(100% - 32px);
    height: 1px;
    content: '';
    background: linear-gradient(
      90deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(196, 196, 196, 0.3) 10.42%,
      rgba(196, 196, 196, 0.3) 87.5%,
      rgba(196, 196, 196, 0) 100%
    );
  }

  ${CopyrightNotice} {
    display: none;

    ${up('md')} {
      display: block;
    }
  }

  ${ExternalLink} {
    font-weight: 400;

    svg {
      margin-left: 5px;
    }

    ${up('md')} {
      font-size: 12px;

      svg {
        width: 8px;
        height: 8px;

        path {
          stroke: ${styleVars.colors.footerArrow};
        }
      }
    }
  }
`
