import { styleVars } from '@/assets/styles/styleVars'
import React, { FC, ReactNode } from 'react'
import { createTheme } from 'styled-breakpoints'
import { ThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

import { AwwwardsRibbon } from './AwwwardsRibbon'
import {
  OptanonCookieBarStyles,
  OptanonCookieConsentOptionsStyles,
} from './Optanon.styles'

interface LayoutProps {
  children: ReactNode
}

const GlobalStyle = createGlobalStyle`
  /* Matter */
  @font-face {
    font-family: Matter;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Matter-Regular.woff2') format('woff2');
    font-display: block;
  }
  @font-face {
    font-family: Matter;
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/Matter-Medium.woff2') format('woff2');
    font-display: block;
  }

  /* Platform */
  @font-face {
    font-family: Platform;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Platform-Regular-Web.woff2') format('woff2');
    font-display: block;
  }

  /* Clash Display */
  @font-face {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/ClashDisplay-Medium.woff2') format('woff2');
    font-display: block;
  }

  * {
    ${styleVars.fonts.matter};
    
    box-sizing: border-box;
  }

  body {
    display: flex;
    flex-direction: column;
    min-width: 375px;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    color: ${styleVars.colors.buttonText};
    background: ${styleVars.colors.pageBg};
  }

  a {
    color: ${styleVars.colors.buttonText};
    text-decoration: none;
  }

  p {
    color: ${styleVars.colors.buttonDescription};
  }

  ${OptanonCookieConsentOptionsStyles}

  ${OptanonCookieBarStyles}
`

const customBreakpointsTheme = createTheme({
  lg: '992px',
  md: '768px',
  sm: '576px',
  xl: '1200px',
  xs: '375px',
  xxl: '1400px',
})

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={customBreakpointsTheme}>
      <GlobalStyle />
      <AwwwardsRibbon />
      {children}
    </ThemeProvider>
  )
}
